import React from "react"
import './styles.scss'
import Img from 'gatsby-image'

const HeadingSection = ({content}) => {
  return (
    <section className="heading">
      <div className="title">{content.title}</div>
      <Img
        className="signature"
        fluid={content.signature.childImageSharp.fluid}
        placeholderStyle={{ visibility: "hidden" }}
      />
      <div className="sub-title">{content.subtitle}</div>
    </section>
  )
}

export default HeadingSection
