import React from "react"
import './styles.scss'
import CustomCarousel from "../../CustomCarousel/CustomCarousel"

const CarouselSection = ({content}) => {
  let images = []

  content.carousel.map(node => {
    const image = {
      name: node.name,
      title: node.title,
      date: node.date ? node.date : null,
      material: node.material ? node.material : null,
      size: node.size ? node.size : null,
      image: node.image.childImageSharp.fluid.src
    }
    images.push(image)
  })

  return (
    <section className="carousel-section">
      <CustomCarousel
        customArrows={true}
        queryImages={images}
        showImageTitle='true'
      />
      <a className="inquire" href="mailto:hello@rainraingallery.com">Inquire</a>
    </section>
  )
}

export default CarouselSection
