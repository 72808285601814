import React, {useState} from "react"
import './styles.scss'
import Img from 'gatsby-image'

const QuoteSection = ({content}) => {
  const [quoteVisible, setQuoteVisible] = useState(false)

  return (
    <section className="quote">
      {
        content.first ?
        <div className="first" dangerouslySetInnerHTML={{ __html: content.first }}></div> :
        null
      }

      {
        content.second ?
        <div className={`wrapper ${content.secondType === 'right' ? 'wrapper--right' : null}`}>
          <div className="deco" onClick={() => {setQuoteVisible(!quoteVisible)}}>
            <Img
              className="signature"
              fluid={content.icon.childImageSharp.fluid}
              placeholderStyle={{ visibility: "hidden" }}
            />
          </div>
          <div className="second" style={{visibility: quoteVisible ? 'visible' : 'hidden'}} dangerouslySetInnerHTML={{ __html: content.second }}></div>
        </div> :
        null
      }
    </section>
  )
}

export default QuoteSection
