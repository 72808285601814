import React, {useState} from "react"
import './styles.scss'
import Img from 'gatsby-image'

const ProfileSection = ({content}) => {
  const [visible, setVisible] = useState(false)

  return (
    <section className="profile">
      <div className="wrapper">
        <div className="text-wrapper">
          <div className="title" onClick={() => {setVisible(!visible)}}>{content.profileTitle}<span>{visible ? '-' : '+'}</span></div>
          <div className="description">{content.profileDescription}</div>
          <div className="credit">{content.profileCredit}</div>
        </div>

        <Img
          style={{visibility: visible ? 'visible' : 'hidden'}}
          className="image"
          fluid={content.profileImage.childImageSharp.fluid}
          placeholderStyle={{ visibility: "hidden" }}
        />
      </div>
    </section>
  )
}

export default ProfileSection
