import React, {useState, useRef, useEffect} from "react"
import { Link } from 'gatsby'
import './styles.scss'
import rightArrow from '../../../svg/right-arrow--white.svg'
import playBtn from '../../../svg/play.svg'
import stopBtn from '../../../svg/stop.svg'
import fullScreenBtn from '../../../svg/full-screen.svg'
import poster from '../../../images/poster.jpg'

const videoLink = 'https://videos.ctfassets.net/yon2u6qy3zpn/45gc5p44SPIJoahPeEKaPh/729f8e72093ea09ac0bf106061b648c0/KOSUKE_SUBTITLE_ENG.mp4'

const VideoSection = () => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [onHover, setOnHover] = useState(false)
  const [clicked, setClicked] = useState(false)
  const videoEl = useRef(null)

  const openFullscreen = () => {
    const elem = videoEl.current

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }
  }

  const handleClick = () => {
    videoEl.current.muted = false
    setClicked(true)
    setIsPlaying(!isPlaying)
  }

  useEffect(() => {
    isPlaying ? videoEl.current.play() : videoEl.current.pause()
  }, [isPlaying])

  return (
    <section className="kosuke-kawahara-video">
      <Link to="/c-speakeasy/kosuke-kawahara/studio-visit">
        <button className="visit-button">
          <div className="text">Studio Visit</div>
          <div className="arrow">
            <img src={rightArrow}/>
          </div>
        </button>
      </Link>

      <div className="video-wrapper">
        <video
          preload='auto'
          poster={poster}
          loop autoPlay muted
          ref={videoEl}
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
          onClick={handleClick}
        >
          <source src={videoLink} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>

        <img className="full-screen-button" src={fullScreenBtn} onClick={openFullscreen}/>

        <img className="button" style={{visibility: onHover ? 'visible' : 'hidden'}} src={isPlaying ? stopBtn : playBtn}/>
        <img className="button" style={{display: clicked ? 'none' : 'block'}} src={playBtn}/>
      </div>
    </section>
  )
}

export default VideoSection
