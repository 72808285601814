import React, { useState, useEffect, useRef } from "react"
import { Link, navigate, useStaticQuery, graphql } from "gatsby"
import Seo from "../../../components/seo"
import MailchimpSubscribe from "react-mailchimp-subscribe"

import './styles.scss'
import signatureImg from '../../../images/speakeasy-1.jpg'
// import video from '../../../videos/video-sample.mp4'
// import poster from '../../../videos/poster.jpg'
import deco1 from '../../../images/deco-1.png'
import CustomCarousel from "../../../components/CustomCarousel/CustomCarousel"
import HeadingSection from '../../../components/kosuke-kawahara/heading-section/HeadingSection'
import VideoSection from '../../../components/kosuke-kawahara/video-section/VideoSection'
import QuoteSection from '../../../components/kosuke-kawahara/quote-section/QuoteSection'
import CarouselSection from '../../../components/kosuke-kawahara/carousel-section/CarouselSection'
import ProfileSection from "../../../components/kosuke-kawahara/profile-section/ProfileSection"

import topArrow from '../../../svg/up-arrow--white.svg'
import rightArrow from '../../../svg/right-arrow.svg'

const url = "https://rainraingallery.us14.list-manage.com/subscribe/post?u=1511c581d31380e2cb0fc607d&id=771f9dc947"


const KosukePage = ({data}) => {
  const content = data.allKosukeKawaharaYaml.edges

  const [isEmailEntered, setIsEmailEntered] = useState(false)

  const removeLocal = () => {
    if(typeof window !== "undefined") {
      localStorage.setItem("email", '')
    }
  }

  useEffect(() => {
    if(typeof window !== "undefined") {
      const query = new URLSearchParams(window.location.search);

      if(localStorage.getItem('email')=="entered" || query.get('pastspeakeasy')) {
        setIsEmailEntered(true)
      }
    }
  }, [])

  const handleBackToTop = () => {
    if(typeof window !== "undefined") {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
	}

  const handleEnterEmail = () => {
    setIsEmailEntered(true)
  }

  return (
    <div className={`kosuke-kawahara ${isEmailEntered ? 'visible' : 'invisible'}`}>
      {/* <div className='mask' style={{display: isEmailEntered ? 'none' : 'flex'}}>
        <div className="inner">
          <div className="first">Please provide your contact information to view this exhibition.</div>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <Form
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
                enterEmail={handleEnterEmail}
              />
            )}
          />
          <div className="second">We will process the personal data you have supplied accrodance with our privacy policy. You can unsbscribe or change your preferences at any time by clicking the link in any emails.</div>
        </div>
      </div> */}
      <Link to="/speakeasy" className="exit">EXIT</Link>
      {
        content.map(node => {
          switch(node.node.type) {
            case 'heading':
              return <HeadingSection content={node.node} />
              break;
            case 'video':
              return <VideoSection content={node.node} />
              break;
            case 'quote':
              return <QuoteSection content={node.node} />
              break;
            case 'carousel':
              return <CarouselSection content={node.node} />
              break;
            case 'profile':
              return <ProfileSection content={node.node} />
              break;
            default:
              // code block
          }
        })
      }
      <div className="back" onClick={handleBackToTop}>
        <span><img src={topArrow}/></span><div className="text">Back to Top</div>
      </div>
    </div>
  )
}

const Form = ({ status, message, onValidated, enterEmail }) => {
  const input = useRef(null)
  // const [inputOnFocus, setInputOnFocus] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [onError, setOnerror] = useState(false)

  // const handleOnBlur = () => {
  //   if(inputValue.length === 0 ) {
  //     setInputOnFocus(false)
  //   }
  // }

  function ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
      {
        return (true)
      }
        return (false)
    }

  let email;

  const handleSubmit = () => {
    if(ValidateEmail(inputValue)) {
      enterEmail()
      if(typeof window !== "undefined") {
        window.localStorage.setItem('email', 'entered')
      }

      submit()
    } else {
      setOnerror(true)
    }
  }

  const submit = () =>
    inputValue.indexOf("@") > -1 &&
    onValidated({
      EMAIL: inputValue
    });

  return (
    <div style={{position: 'relative'}}>
      {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
      {status === "error" && (
        <div
          style={{ color: "red" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          style={{ color: "green" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <input
        style={{width: '100%', borderBottom: '2px white solid', paddingBottom: '4px', border: 'none', borderBottom: '1px black solid', outline: 'none'}}
        ref={input}
        ref={node => (email = node)}
        type="email"
        // onFocus={() => {setInputOnFocus(true)}}
        // onBlur={handleOnBlur}
        onChange={e => {setInputValue(e.target.value)}}
        placeholder='Email Address'
      />

      <div className="errorMsg" style={{visibility: onError ? 'visible' : 'hidden'}}>Please enter valid email</div>

      <div
          className="button"
          onClick={handleSubmit}
          style={{color: 'white', position: 'absolute', right: '0', bottom: '4px', paddingRight: '0'}}
        >Enter</div>
    </div>
  );
};

export const query = graphql`
  query {
    allKosukeKawaharaYaml {
      edges {
        node {
          type
          title
          firstType
          secondType
          profileTitle
          profileDescription
          profileCredit
          profileImage {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          signature {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          subtitle
          first
          icon {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          second
          carousel {
            name
            title
            date
            material
            size
            image {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default KosukePage
